<template>
    <div>
        <Loading :loading="loading"/>
        <Edit
            :tipo="tipoSelecionado"
            :campos="camposEditados"
            :close="closeEdit"
            :onSubmit="onSubmit"
        />
        <CAlert show color="light">
            Campos mapeados são utilizados no momento da integração com plataformas de terceiros,
            é uma forma de normatizar tanto os dados de saída quanto os de entrada.
        </CAlert>
        <CTabs variant="tabs" class="nav-tabs-boxed">
            <CTab title="Campos mapeados">
                <ListaCampos
                    :tipos="tipos"
                    :campos="campos"
                    :onEdit="openEdit"
                />
            </CTab>
        </CTabs>
    </div>
</template>

<script>
  import { get, put } from '../../helpers/apiRequest';
  import ListaCampos from "./components/ListaCampos";
  import Edit from "./components/Edit";
  import clone from 'just-clone';

  export default {
    name: "CamposIntegracao",
    components: {
      Edit,
      ListaCampos
    },
    data() {
      return {
        'tipos': [],
        'campos': [],
        'loading': false,

        // Obtjeto que está sendo editado
        'tipoSelecionado': {},
        'camposEditados': [],
      };
    },
    methods: {
      openEdit(tipoChave) {
        this.tipoSelecionado = clone(this.tipos[tipoChave]);
        this.tipoSelecionado.chave = tipoChave;
        this.camposEditados  = this.campos[tipoChave] ? clone(this.campos[tipoChave]) : [];
        this.$modal.show('editCampo');
      },
      closeEdit() {
        this.$modal.hide('editCampo');
      },
      loadTipos() {
        get(`/admin/tipos-campos-integracao`)
          .then((response) => this.tipos = response);
      },
      loadCampos() {
        get(`/admin/campos-integracao`)
          .then((response) => this.campos = response);
      },
      onSubmit(campos, tipo) {
        this.loading = true;
        put(`/admin/campos-integracao/${tipo}`, campos)
          .then((response) => {
            this.$swal({
              title: 'Sucesso!',
              text: response.message,
              icon: 'success',
            });
            this.loadCampos();
            this.closeEdit();
            this.loading = false;
          })
          .catch((err) => {
            this.$swal({
              title: 'Ops!',
              text: err.message,
              icon: 'error',
            });
            this.loading = false;
          })
      }
    },
    beforeMount() {
      this.loadTipos();
      this.loadCampos();
    }
  }
</script>
