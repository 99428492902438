<template>
    <div>
        <modal name="editCampo" height="auto" width="1200" :scrollable="true" :clickToClose="false">
            <CloseModal :close="close"/>
            <div class="modalbox query-tarefa">
                <div class="modalbox-content">
                    <CTabs variant="tabs" class="nav-tabs-boxed">
                        <CTab :title="tipo.titulo">
                            <CCard v-if="campos.length === 0">
                                <CCardBody>
                                    <div class="row">
                                        <div class="col text-center">
                                            😕 Ops! Parece que você não tem nenhum campo mapeado ainda para este tipo de
                                            integração,
                                            clique no botão abaixo para mapear seu primeiro campo.
                                        </div>
                                    </div>
                                </CCardBody>
                            </CCard>
                            <CCard v-for="(campo, idx) in campos"
                                   :class="{'border-danger' : campo.de.trim().length === 0 || campo.para.trim().length === 0 }"
                                   :key="idx">
                                <CCardBody>
                                    <div class="row">
                                        <div class="col col-3">
                                            <label>{{ campoDe(campo.de) }}</label>
                                            <input
                                                class="form-control"
                                                :class="{'is-invalid': campo.de.trim().length === 0}"
                                                type="text"
                                                v-model="campo.de"
                                            >
                                            <button v-if="possuiCamposDe" @click="openModalCamposDisponiveisDe(campo)">
                                                Selecionar da lista
                                            </button>
                                        </div>
                                        <div class="col col-3">
                                            <label>{{ campoPara(campo.para) }}</label>
                                            <input
                                                class="form-control"
                                                :class="{'is-invalid': campo.para.trim().length === 0}"
                                                type="text"
                                                v-model="campo.para"
                                            >
                                            <button v-if="possuiCamposPara" @click="openModalCamposDisponiveisPara(campo)">
                                                Selecionar da lista
                                            </button>
                                        </div>
                                        <div class="col col-2">
                                            <label>Valor padrão</label>
                                            <input
                                                    class="form-control"
                                                    type="text"
                                                    v-model="campo.valor_padrao"
                                            >
                                        </div>
                                        <div class="col col-1">
                                            <label>Formato</label>
                                            <select v-model="campo.conversao" class="form-control">
                                                <option :value="null">Nenhum</option>
                                                <option value="int">Inteiro</option>
                                                <option value="float">Decimal</option>
                                                <option value="boolean">Booleano</option>
                                                <option value="string">Texto</option>
                                                <option value="binary">Binário</option>
                                                <option value="json">JSON</option>
                                            </select>
                                        </div>
                                        <div v-if="validaFormato(campo)" class="col col-2">
                                            <label>Inverte sinal</label>
                                            <select v-model="campo.inverte_sinal" class="form-control">
                                                <option value="1">Sim</option>
                                                <option value="0">Não</option>
                                            </select>
                                        </div>
                                        <div class="col col-2">
                                            <label>Incluir se nulo</label>
                                            <select v-model="campo.incluir_se_nulo" class="form-control">
                                                <option value="1">Sim</option>
                                                <option value="0">Não</option>
                                            </select>
                                        </div>
                                        <div class="col col-1 text-center">
                                            <label>Excluir &nbsp;</label>
                                            <button type="button" name="button" v-on:click="onRemoveCampo(idx)"
                                                    class="button button-error button-sm">
                                                <fa-icon icon="trash-alt"/>
                                            </button>
                                        </div>
                                    </div>
                                </CCardBody>
                            </CCard>

                            <div class="row">
                                <div class="col">
                                    <button class="button-success button-sm" @click="onAdd">
                                        Incluir campo
                                    </button>
                                </div>
                            </div>

                            <div>
                                Os campos acima listados serão alterados ou adicionados ( caso não contenham no modelo padrão ).
                                O valor a ser utilizado será obtido através do campo "DE" e enviado para integração através do campo "PARA".
                                É possível forçar a transformação para um determinado tipo, como inteiro, decimal, etc.
                            </div>
                        </CTab>
                    </CTabs>
                </div>
            </div>
            <div class="modal-footer">
                <button :class="{ 'button button-success': true, 'button-disabled': !camposValidos }"
                        v-on:click="handleSubmit">
                    Atualizar
                </button>
                <button class="button" v-on:click="close">Fechar</button>
            </div>
        </modal>
        <CamposDisponiveis
            :campos="tipo.de"
            nome="camposDisponiveisDe"
            :onSelect="alterarCampoDe"
        />
        <CamposDisponiveis
            :campos="tipo.para"
            nome="camposDisponiveisPara"
            :onSelect="alterarCampoPara"
        />
    </div>
</template>

<script>
  import CloseModal from "../../../components/CloseModal";
  import CamposDisponiveis from "./CamposDisponiveis";
  import { isEmpty } from "lodash";

  const campoMapeado = (tipo) => ({
    'de': '',
    'para': '',
    'conversao': null,
    'inverte_sinal': 0,
    'incluir_se_nulo': 1,
    'valor_padrao': 1,
    'ordem': 1,
    tipo
  });

  export default {
    name: "Edit",
    components: {
      CamposDisponiveis,
      CloseModal,
    },
    props: [
      'close',
      'tipo',
      'campos',
      'onSubmit',
    ],
    data() {
      return {
        'campoEmEdicao': {}
      }
    },
    computed: {
      camposValidos() {
        let valido = true;

        this.campos.forEach((campo) => {
          if (campo.de.trim().length === 0 || campo.para.trim().length === 0) {
            valido = false;
          }
        });

        return valido;
      },

      possuiCamposDe() {
        return !isEmpty(this.tipo.de)
      },
      possuiCamposPara() {
        return !isEmpty(this.tipo.para)
      },
    },
    methods: {
      validaFormato(campo) {
        return campo.conversao == 'int' || campo.conversao == 'float'
      },
      alterarCampoDe(novoValor) {
        this.campoEmEdicao.de = novoValor;
      },
      alterarCampoPara(novoValor) {
        this.campoEmEdicao.para = novoValor;
      },
      openModalCamposDisponiveisDe(campo) {
        this.campoEmEdicao = campo;
        this.$modal.show('camposDisponiveisDe');
      },
      openModalCamposDisponiveisPara(campo) {
        this.campoEmEdicao = campo;
        this.$modal.show('camposDisponiveisPara');
      },
      campoDe(nomeCampo) {
        return 'De: ' + (this.tipo.de[nomeCampo] ? this.tipo.de[nomeCampo] : 'Campo customizado');
      },
      campoPara(nomeCampo) {
        return 'Para: ' + (this.tipo.para[nomeCampo] ? this.tipo.para[nomeCampo] : 'Campo customizado');
      },
      onAdd() {
        this.campos.push(campoMapeado(this.tipo.chave));
      },
      onRemoveCampo(index) {
        this.campos.splice(index, 1)
      },
      handleSubmit() {
        this.onSubmit(
          this.campos,
          this.tipo.chave
        )
      }
    }
  }
</script>